import { NgModule } from '@angular/core';
import { PuntosDeVentaComponent } from './puntos-de-venta.component';
import { ProductosComponent } from './productos/productos.component';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { TextAreaSegModule } from '@wo/frontend/text-area-seg/text-area-seg.module';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CantidadPopUpComponent } from './cantidad-pop-up/cantidad-pop-up.component';
import { PorcentajeProductoPopupComponent } from './porcentaje-producto-popup/porcentaje-producto-popup.component';
import { ListadoDocumentoEncabezadoComponent } from '@wo/frontend/documentos/listado/listado-documento-encabezado/listado-documento-encabezado.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { DetallePopupComponent } from "@wo/pos/puntos-de-venta/detalle-pop-up/detalle-popup.component";
import { NotaPopUpComponent } from './nota-pop-up/nota-pop-up.component';
import { EncabezadoCompletoPipe } from './pipes/encabezado-completo.pipe';
import { CopiarDocumentoPosComponent } from './copiar-documento-pos/copiar-documento-pos.component';
import { OpcionesFinalesComponent } from './opciones-finales/opciones-finales.component';
import { AcordeonSelectSegModule } from '@wo/frontend/acordeon-select-seg/acordeon-select-seg.module';
import { ElegirRenglonesNotasComponent } from './copiar-documento-pos/elegir-renglones-notas/elegir-renglones-notas.component';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { FormatearNumeroModule } from './pipes/formatear-numero.module';
import { DetallePagoComponent } from './detalle-pago/detalle-pago.component';
import { AgregarFormaPagoComponent } from './detalle-pago/agregar-formapago-popup/agregar-formapago-popup.component';
import { ImprimirPosComponent } from './imprimir/imprimir.component';
import { SinConexionComponent } from './sin-conexion/sin-conexion.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CompactarNumeroModule } from './pipes/compact-numero.module';
import { BotonWoSegModule } from '@wo/frontend/boton-wo-seg/boton-wo-seg.module';
import { MenuContextualModule } from '@wo/frontend/menu-contextual/menu-contextual.module';
import { FechasModule } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/fechas/fechas.module';
import { WoFileUploadModule } from '@wo/frontend/wo-file-upload/wo-file-upload.module';
import { MultiselectSegModule } from '@wo/frontend/multiselect-seg/multiselect-seg.module';
import { PorcentajeFacturaPopupComponent } from './porcentaje-factura-popup/porcentaje-factura-popup.component';
import { CrearTerceroComponent } from './encabezado/crear-tercero/crear-tercero.component';
import { CrearDireccionTerceroModule } from './encabezado/crear-direccion-tercero/crear-direccion-tercero.module';
import { CopiarDocumentoModule } from "@wo/frontend/copiar-documento/copiar-documento.module";
import { CrearDocumentosComponent } from './crear-documentos/crear-documentos.component';
import { CruzarDocumentosComponent } from './cruzar-documentos/cruzar-documentos.component';
import { MatRadioModule } from '@angular/material/radio';
import { VerNumeroEnviarPipe } from './pipes/ver-numero-enviar.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatSelectModule } from '@angular/material/select';
import { MultiSelectModule } from '@wo/frontend/multiselect/multiselect.module';
import { ResizableModule } from 'angular-resizable-element';
import { ObsequioPopupComponent } from './obsequio-pop-up/obsequio-pop-up.component';
import { ElegirResolucionesPopupComponent } from './elegirResolucionesDocElectronico/elegir-resoluciones-pop-up.component';
import { MasInventarioModule } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-inventario/mas-inventario.module';
import { ViewerComponent } from '@wo/frontend/reporte/viewer/viewer.component';
import { ValidacionDocumentosElectronicosPopUpComponent } from './validacion-documentos-electronicos-pop-up/validacion-documentos-electronicos-pop-up.component';
import { ImprimirDocumentoModule } from '@wo/frontend/documentos/detalle/documento-encabezado/imprimir-documento/imprimir-documento.module';
import { ContabilizacionModule } from '@wo/frontend/documentos/detalle/documento-encabezado/contabilizacion/contabilizacion.module';
import { DetalleAbonaModule } from '@wo/frontend/documentos/detalle/documento-encabezado/detalle-abona/detalle-abona.module';
import { MasOpcionesModule } from '@wo/frontend/documentos/detalle/documento-encabezado/mas-opciones/mas-opciones.module';
import { PopUpListadoModule } from '@wo/frontend/documentos/detalle/documento-encabezado/renglones-documento/movimiento-inventario/pop-up-listado/pop-up-listado.module';
import { PopupResolverExistenciasCruceModule } from '@wo/frontend/documentos/detalle/documento-encabezado/cruce-documentos/cruce-inventarios/popup-resolver-existencias-cruce/popup-resolver-existencias-cruce.module';
import { ValidacionesModule } from '@wo/frontend/documentos/detalle/documento-encabezado/cruce-documentos/validaciones/validaciones.module';
import { ValidacionesPipesModule } from '@wo/frontend/documentos/detalle/documento-encabezado/validaciones/validaciones-pipes.module';
import { PopUpLotesComponent } from './pop-up-lotes/pop-up-lotes.component';
import { PopUpSerialesComponent } from './pop-up-seriales/pop-up-seriales.component';
import { PopUpTallacolorComponent } from './pop-up-tallacolor/pop-up-tallacolor.component'
import { DocumentoPesonalizadoRenglonModule } from '@wo/frontend/documentos/detalle/documento-encabezado/renglones-documento/pesonalizado-renglon/documento-pesonalizado-renglon.module';

@NgModule({
  declarations: [
    PuntosDeVentaComponent,
    ProductosComponent,
    EncabezadoComponent,
    CantidadPopUpComponent,
    ListadoDocumentoEncabezadoComponent,
    PorcentajeProductoPopupComponent,
    PorcentajeFacturaPopupComponent,
    NotaPopUpComponent,
    CopiarDocumentoPosComponent,
    ElegirRenglonesNotasComponent,
    DetallePopupComponent,
    EncabezadoCompletoPipe,
    OpcionesFinalesComponent,
    DetallePagoComponent,
    AgregarFormaPagoComponent,
    ImprimirPosComponent,
    SinConexionComponent,
    CrearTerceroComponent,
    CrearDocumentosComponent,
    CruzarDocumentosComponent,
    VerNumeroEnviarPipe,
    ObsequioPopupComponent,
    ElegirResolucionesPopupComponent,
    PopUpLotesComponent,
    PopUpSerialesComponent,
    PopUpTallacolorComponent,
    ViewerComponent,
    ValidacionDocumentosElectronicosPopUpComponent
  ],
  imports: [
    AngularDraggableModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    WoTextFieldSegModule,
    WoTextFieldModule,
    SelectPagSegModule,
    TextAreaSegModule,
    AcordeonSelectSegModule,
    WoTablaModule,
    KeyboardShortcutsModule.forRoot(),
    FormatearNumeroModule,
    CompactarNumeroModule,
    MatSnackBarModule,
    BotonWoSegModule,
    MenuContextualModule,
    FechasModule,
    WoFileUploadModule,
    MultiselectSegModule,
    CrearDireccionTerceroModule,
    CopiarDocumentoModule,
    MatRadioModule,
    NgxIntlTelInputModule,
    MatSelectModule,
    CopiarDocumentoModule,
    MultiSelectModule,
    ResizableModule,
    MasInventarioModule,
    ImprimirDocumentoModule,
    ContabilizacionModule,
    DetalleAbonaModule,
    MasOpcionesModule,
    PopUpListadoModule,
    PopupResolverExistenciasCruceModule,
    ValidacionesModule,
    DocumentoPesonalizadoRenglonModule,
    ValidacionesPipesModule
  ],
  exports: [
    PuntosDeVentaComponent,
    ProductosComponent
  ]
})
export class PuntosDeVentaModule { }
