export class OpcionTabla {
  static EDITAR = 'Editar';
  static ELIMINAR = 'Eliminar';
  static REVOCAR = 'Revocar';
  static GENERAR_TOKEN = 'generarToken';
  static ABRIR_CONCILIACION = 'abrirConciliacion';
  static VER_NOTA_AJUSTE = 'verNotaAjuste';
  static VER_INFORME_CONCILIACION = 'verInformeConciliacion';
  static VER_PROVISION = 'verProvision';
  static VER_CALCULOS = 'Ver Cálculos';
  static VER = 'Ver';
  static CREAR = 'Crear';
  static ADMINISTRAR = 'Administrar';
  static ADMINISTRAR_ACTIVIDADES = 'AdminActiv';
  static COPIAR_CONTABILIZACIONES = 'copiarContabilizaciones';
  static VER_IMPUESTOS = 'VerImpuestos';
  static TELEFONO = 'Telefono';
  static EMAIL = 'Email';
  static VER_CONTABILIZACION = 'Ver Contabilización';
  static MAS = 'Más Información';
  static ASIGNAR_USUARIOS = 'AsignarUsuarios';
  static COPIAR = 'Copiar';
  static VER_MULTIMONEDA = 'Multimoneda';
  static CONFIGURAR_TIPO_PAGO = 'TipoPago';
  static ENVIAR_EMAIL = 'enviaremail';
  static DESCARGAR = 'Descargar';
  static DESCARGAR_PDF = 'DescargarPDF';
  static DESCARGAR_XML = 'DescargarXML';
  static ASISTENTE_CONFIGURACION_SERVICIO = 'verCuentasServicio';
  static CREAR_HIJO = 'crearHijoTablaArbol';
  static MOVER_HIJO = 'moverHijoTablaArbol';
  static DESCONECTAR = 'Desconectar';
  static INACTIVAR = 'Inactivar';
  static ACTIVAR = 'Activar';
  static VER_CUENTAS = 'VerCuentas';
  static VER_CONCEPTO = 'VerConcepto';
  static ASOCIAR_FIRMA = 'asociarFirma';
  static EXPORTAR_TABLA_EXCEL = 'ExportarExcel';
  static DIFERIDO_MANUAL = 'diferidoManual';
  static ACTIVO_FIJO_MANUAL = 'verActivoFijo';
  static VER_CRUCE = 'verCruce';
  static ABRIR_DOCUMENTO = 'abrirDocumento';
  static VER_IMPUESTO_CLASIFICACION = 'verClasificacionesImpuesto';
  static DEPENDE_OTRO_CONCEPTO = 'senDependeOtroConcepto';
  static IMPUESTOS_NO_CALCULADOS = 'impuestosNoCalculados';
  static RESETANCHOS = 'resetAnchos';
  static VERDIFERIDO = 'VerDiferido';
  static CAMPOSPERSONALIZADOS = 'camposPersonalizados';
  static INFORMACION_TRIBUTARIA = 'InformacionTributaria';
  static NOTA_IMPRIMIBLE = 'NotaImprimible';
  static SIN_OPCIONES = 'sinOpciones';
  static OBSEQUIO = 'senObsequio';
  static LOTES = 'verLotes';
  static PRORROGAS = 'verProrrogas';
  static FICHA_PERSONAL = 'fichaPersonal';
  static LISTADO_PRECIOS = 'Lista de Precios';
  static CONTABILIZACION = 'contabilizacion';
  static RECALCULAR_CONCEPTOS_RECURRENTES = 'recalcularConceptosRecurrentes';
  static VALOR_PREDETERMINADO = 'valorPredeterminado';
  static TERMINALES = 'verTerminales';
  static ACEPTACION_TACITA = 'aceptacionTacita';
  static ACEPTACION_EXPRESA = "aceptacionExpresa"
  static RECLAMO_FACTURA_VENTA = "reclamoFVE"
  static RECIBO_DEL_BIEN = "reciboDelBien"
  static ACUSE_FACTURA_VENTA = "acuseFVE"
  static NO_EXISTE_EVENTOS = "noExitesEventos"
  static ACTUALIZAR_ESTADO = "ActualizarEstado"
  static AUDITORIA_CUENTA = "auditoriaCuenta"
  static ID_CONTRATO = "idContrato"
  static AUDITORIA_APROBACION = "verAuditoriaAprobacion";
  static AJUSTES_ELIMINACION = "AjusteEliminacion";
  static TALLACOLOR = "verTallaColor";
  static SERIALES = "verSeriales";
  static AJUSTE_REEMPLAZO = "AjusteReemplazo";
  static CALCULO_REDONDEOS = "calcularRedondeosNC";
  static VER_NOVEDADES_PERIODO = "verNovedadesPeriodo";
  static VISTA_PREVIA_SEGURIDAD = "vistaPreviaSeguridadSocial";
  static VISTA_PREVIA_SEGURIDAD_GENERAL = "informeVistaPreviaGeneralAportes";
  static DESCARGAR_ARCHIVO_PLANO = "descargarArchivoPlano";
  static PAGAR_SEGURIDAD_SOCIAL = "pagarSeguridadSocial";
  static INFORME_AUDITORIA_NP = "informeAuditoriaNominaPila";
  static TEXTOS_CLAVE = "verTextosClave";
  static MAS_OPCIONES = "masOpciones";
  static SECTOR_TRANSPORTE = "verSectorTransporte";
}
