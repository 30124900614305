<div oncontextmenu="return false;" class="contBody" id="containerBodyApp">

  <header [ngClass]="{'estilosMenuPOS':POS}">
    <app-menu id="menu" #menu [POS]="POS" [onlineOffline]="onlineOffline" (cerrarOtrosMenus)="cerrarMenus()"></app-menu>
    <div class="logoHeader" (click)="volverAlHome(); aparecen[1].muestra = false">
      <a [routerLink]="" title="{{'woBoard.worldOffice' | translate}}">
        <img [src]="POS ? './assets/images/logoWOWhitePOS.png': './assets/images/logoWOWhite.png'" />
      </a>
    </div>
    <div class="iconsHeader">
      <ul>
        <li>
          <a title="{{'woBoard.menu' | translate}}" (click)="mostrarAcordeon()">
            <div *ngIf="mostrar">
              <img src="./assets/images/iconsHeader/icoHeaderMenu.png">
            </div>
            <div *ngIf="!mostrar">
              <img src="./assets/images/iconsHeader/icoHeaderMenu.png" style="transform: rotate(180deg);">
            </div>
          </a>
        </li>
        <li *ngIf="POS" id="contenedorOpcionesMenu" class="flex">
          <span *ngIf="!listadoAbierto && !puntoVentaAbierto && onlineOffline; else disabledTerminal"
            id="cambioTerminal" class="tool contenedorTerminalSel" data-tip='Haz click para cambiar la terminal'
            tabindex="1">
            <div class="flex" (click)="cambiarTerminal()">
              <i class="fas fa-cash-register"></i>
              <p>{{terminalSel.nombre}}</p>
            </div>
          </span>
          <ng-template #disabledTerminal>
            <span id="cambioTerminal" class="tool contenedorTerminalSel disabledTerminal"
              data-tip='Para cambiar la terminal se necesita conexión a internet y no puede estar abierto el listado de facturas o punto de venta.'
              tabindex="1">
              <div class="flex">
                <i class="fas fa-cash-register"></i>
                <p>{{terminalSel.nombre}}</p>
              </div>
            </span>
          </ng-template>

          <span
            *ngIf="terminalManejaTurno && onlineOffline && (!puntoVentaAbierto || !turnoActivo?.codigo); else disabledTurno"
            id="turnoEmpleado" class="tool contenedorTurno"
            data-tip='Haz click para ver la información del Turno Activo' tabindex="1">
            <div class="flex" (click)="verTurnoActual()">
              <i class="fas fa-clock"></i>
              <p>{{turnoActivo ? turnoActivo.nombre : 'Sin Turno'}}</p>
            </div>
          </span>

          <ng-template #disabledTurno>
            <span *ngIf="terminalManejaTurno" id="cambioTerminal" class="tool contenedorTurno disabledTerminal"
              data-tip='Para ver la auditoria del turno se necesita conexión a internet y no puede estar abierto ningún documento del punto de venta.'
              tabindex="1">
              <div class="flex">
                <i class="fas fa-clock"></i>
                <p>{{turnoActivo ? turnoActivo.nombre : 'Sin Turno'}}</p>
              </div>
            </span>
          </ng-template>
        </li>
        <li id="buscadorGeneral">
          <form class="example-form">
            <i *ngIf="datoABuscar.value" id="limpiarBuscador" class="fas fa-times"
              (click)="datoABuscar.setValue(''); inicializarAutocomplete();"></i>
            <i class="fas fa-search"></i>
            <input id="inputBuscar" name="buscador" type="text" placeholder="Buscar..." [formControl]="datoABuscar"
              [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let ruta of listaRutasMostrar | async" [value]="ruta" [title]="ruta['nombreMostrar']">
                {{ruta['nombreMostrar']}}
              </mat-option>
            </mat-autocomplete>
          </form>
        </li>
        <li>
          <a *ngIf="!POS" id="notificaciones" title="{{'woBoard.notificaciones' | translate}}"
            (click)="mostrarNotificaciones()">
            <i class="fas fa-bell" matBadge="{{notificaciones.length}}"
              matBadgeColor="{{notificaciones.length > 0 ? 'warn':''}}"></i>
          </a>
        </li>
        <li>
          <a *ngIf="!POS" id="archivos" title="{{'woBoard.archivosLicencia' | translate}}"
            (click)="popupArchivos = true">
            <i class="fas fa-folder-open"></i>
          </a>
        </li>
        <li *ngIf="!POS; else SYNCPOS">
          <a id="cservice" title="{{'woBoard.centroServicios'| translate}}" href="#/centro-servicios" target="_blank">
            <i class="fas fa-headset"></i>
          </a>
        </li>
        <ng-template #SYNCPOS>
          <li>
            <a id="cservice" class="syncPOS" title="{{'woBoard.sincronizarPOS'| translate}}"
              (click)="clickSincronizar = true; sincronizarPOS()"
              [ngClass]="{'offline': !onlineOffline, 'pendienteSincronizacion': pendienteSincronizacion}">
              <i class="fas fa-sync-alt" [ngClass]="{'fa-spin': sincronizando}"></i>
            </a>
          </li>
        </ng-template>
        <li>
          <a id="user" title="{{'woBoard.usuario' | translate}}" (click)="mostrarInformacionUsuario()">
            <i class="fas fa-user-circle"></i>
          </a>
        </li>
      </ul>
    </div>

    <!-- DROP NOTIFICACIONES -->
    <div *ngIf="aparecen[3].muestra">
      <div id="dropNotificaciones" class="dropMenu">
        <div id="contenedorNotificaciones">
          <div *ngIf="loadingNotificaciones" id="loadingNotificaciones" class="flex">
            <i class="fas fa-spinner fa-spin"></i>
            <p>{{'woBoard.cargandoNotificaciones' | translate}}</p>
          </div>
          <ng-container *ngIf="!loadingNotificaciones">
            <div *ngIf="notificaciones.length == 0" id="sinNotificaciones" class="flex">
              <i class="fas fa-comment-slash"></i>
              <p>{{'woBoard.sinNotificaciones' | translate}}</p>
            </div>
            <ng-container *ngIf="notificaciones.length > 0">
              <div class="cardNotificacion" *ngFor="let card of notificaciones; let i = index">
                <p class="contenedorTituloNotificacion">
                  <span class="tituloNotificacion" [ngClass]="{'alert': card.alert}"
                    (click)="extenderNotificacionPopup(card, i)">{{card.titulo | translate}}</span>
                  <span class="fechaNotificacion">{{card.fechaAgo}}</span>
                </p>
                <i *ngIf="!card.mantenimiento" class="cerrar fas fa-times"
                  (click)="cerrarNotificacion(false, card, i)"></i>
                <i class="fas {{card.alert ? 'fas fa-exclamation-triangle':'fa-info-circle'}} icono flex"
                  [ngClass]="{'alert': card.alert}" (click)="extenderNotificacionPopup(card, i)"></i>
                <div *ngIf="!card.imagen || card.tituloAccion == 'Descargar'; else imagen" class="textoNotificaciones"
                  [ngClass]="{'alert': card.alert}">
                  <span [innerHTML]="card.texto" (click)="extenderNotificacionPopup(card, i)"></span>
                  <br *ngIf="card.tituloAccion">
                  <button *ngIf="card.tituloAccion" class="accionNotificacion" (click)="clickAccionNotificacion(card)"
                    [ngClass]="{'youtube': card.irYoutube, 'linkExterno': card.irLinkExterno}">
                    <i
                      class="{{card.irYoutube ? 'fab fa-youtube' : card.irLinkExterno ? 'fas fa-link' : card.descargar ? 'fas fa-save' : 'fas fa-angle-double-right'}}"></i>
                    {{card.tituloAccion | translate}}</button>
                </div>
                <ng-template #imagen>
                  <div class="textoNotificaciones" (click)="extenderNotificacionPopup(card, i)">
                    <span>{{'woBoard.notificacionImagen' | translate}}</span>
                  </div>
                </ng-template>
              </div>
              <div id="eliminarNotificaciones" class="flex" (click)="cerrarNotificacion(true)">
                <i class="fas fa-trash"></i>
                <p>{{'woBoard.eliminarNotificaciones' | translate}}</p>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <!--DROP MENU-->
    <div *ngIf="aparecen[1].muestra">
      <div class="dropMenu" style="right:0;">
        <div class="infoUsuario">
          <div class="fotoUsuario">
            <i class="fas fa-id-card"></i>
          </div>
          <div class="nombreUsuario">
            <p>{{currentUser}}</p>
            <!-- <input name="" type="button" value="{{ 'ENTORNO.CUENTA' | translate }}"> -->
          </div>
          <div class="numLicencia">
            <p><strong>Número de Suscripción</strong></p>
            <p>{{licenciaActual}}</p>
            <!-- <input name="" type="button" value="{{ 'ENTORNO.CUENTA' | translate }}"> -->
          </div>
          <div class="numLicencia">
            <p><strong>Base de Datos</strong> </p>
            <p *ngIf="nombreLicencia != null">{{nombreLicencia}}</p>
          </div>
        </div>
        <ul *ngIf="!('' | comprobarConfig)" id="modoOscuro" class="cerrarSesion">
          <!--  -->
          <li>
            <i class="fas fa-adjust"></i>
            <a>{{ 'ENTORNO.MODO_OSCURO' | translate }}</a>
            <div class="checkboxModoOscuro">
              <input type="checkbox" id="checkAvanzado" [(ngModel)]="modoOscuro"
                (ngModelChange)="cambiarModoOscuro()" />
              <label for="checkAvanzado">TEXTO QUE NO DEBERÍA VERSE</label>
            </div>
          </li>
        </ul>
        <ul *ngIf="!('' | comprobarConfig)" id="cambiarPass" class="cerrarSesion" (click)="cambiarPass()">
          <!--  -->
          <li>
            <i class="fas fa-unlock-alt"></i>
            <a>{{ 'ENTORNO.CAMBIAR_CONTRASENA' | translate }}</a>
          </li>
        </ul>
        <ul *ngIf="!('' | comprobarConfig) && !POS" id="abrirConfiguracionLicencia" class="cerrarSesion"
          (click)="abrirConfiguracionLicencia(); aparecen[1].muestra=false;">
          <!--  -->
          <li>
            <i class="fas fa-cog"></i>
            <a>{{ 'ENTORNO.ABRIR_CONF_LICENCIA' | translate }}</a>
          </li>
        </ul>
        <ul id="logout" class="cerrarSesion" (click)="logout()">
          <!--  -->
          <li>
            <i class="fas fa-sign-out-alt"></i>
            <a>{{ 'ENTORNO.CERRARSESION' | translate }}</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div *ngIf="aparecen[0].muestra">
      <div class="dropMenu" style="right:102px;"> -->
    <!--borrar style-->
    <!-- <div class="infoLanguage">

          <select #langSelect (change)="translate.use(langSelect.value)" class="form-control" (change)="cerrarAlSeleccionar()"
            [(ngModel)]="langActual">
            <option class="optSelect" *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === translate.currentLang">{{'ENTORNO.'+lang
              | translate}}</option>
          </select>

        </div>
      </div>
    </div> -->

    <!-- <div *ngIf="aparecen[2].muestra">
      <div class="dropMenu" style="left:86%;"> -->
    <!--borrar style-->
    <!-- <div class="infoCompra">
          <ul>
            <li>
              <a>Inglés (US)</a>
            </li>
            <li>
              <a>Español (CO)</a>
            </li>
            <li>
              <a>Español (EC)</a>
            </li>
            <li>
              <a>
                <span class="ui-menuitem-text">Español (PE)</span>
              </a>
            </li>
            <li>
              <a>
                <span class="ui-menuitem-text">Español (CR)</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->

  </header>

  <!--   <div *ngIf="mostrar">
    <nav (click)="entornoClick()">
      <app-menu></app-menu>
    </nav>
  </div> -->

  <div *ngIf="!mostrar">
    <!-- <app-menu *ngIf="true"></app-menu> -->
    <!-- <nav (click)="entornoClick()">
    </nav> -->
    <!-- <div class="search">
      <input name="" type="text" />
    </div> -->
  </div>
  <div class="{{wrapper}}" (click)="entornoClickCerrado()">
    <app-notificacion></app-notificacion>

    <app-tabs></app-tabs>

  </div>
  <div class="curtainCont">
    <div class="curtainPanel">
      <div class="leftCurtain curtain"></div>
      <div class="rightCurtain curtain"></div>
    </div>
  </div>
</div>
<footer>
  <div class="text-align-center">
    <p> Copyright © {{ currentYear }} - World Office Colombia S.A.S. Todos los derechos reservados </p>
  </div>
</footer>

<ng-container *ngIf="mensajeCabecera">
  <input id="cerrarNotify" class="cerrarNotify" type="checkbox" checked>
  <div class="floatNotify" id="floatNotify">
    <label for="cerrarNotify">X</label>
    <div class="cont">
      <div class="icon"><span><img src="/assets/images/iconNotify.png" alt="Notificaciones World Office Cloud" /></span>
      </div>
      <div class="text"><span>
          <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at ligula volutpat,
          placerat odio sed, aliquet mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc id pharetra
          arcu. Duis molestie ipsum lacus, at porta est ultricies ut. Curabitur viverra lectus non mi elementum
          eleifend. Nullam dignissim interdum arcu eget aliquet. Suspendisse at laoreet arcu. Praesent consectetur ante
          eu bibendum rutrum. -->
        </span></div>
    </div>
  </div>
</ng-container>

<app-cambiar-contrasena [id]="id"></app-cambiar-contrasena>

<ng-container *ngIf="popupImagen">
  <app-popup-imagen [img]="imagenPopup" [imgVertical]="imagenPopup" [base64]="base64" (cerrar)="popupImagen = false">
  </app-popup-imagen>
</ng-container>

<ng-container *ngIf="popupArchivos">
  <app-popup-archivos (cerrar)="popupArchivos = false"></app-popup-archivos>
</ng-container>
