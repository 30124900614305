import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { MasOpcionesComponent } from './mas-opciones.component';
import { DocumentoPersonalizadoComponent } from './documento-personalizado/documento-personalizado.component';
import { ElaboradoPorComponent } from './elaborado-por/elaborado-por.component';
import { FechasSinRefComponent } from './fecha-sin-ref/fecha-sin-ref.component';
import { ListaClasificacionesComponent } from './lista-clasificaciones/lista-clasificaciones.component';
import { ListaContactosComponent } from './lista-contactos/lista-contactos.component';
import { MasOtrosComponent } from './mas-otros/mas-otros.component';
import { TerceroExternoComponent } from './tercero-externo/tercero-externo.component';
import { TercerosMultiplesComponent } from './terceros-multiples/terceros-multiples.component';
import { FechasModule } from './fechas/fechas.module';
import { WoFileUploadModule } from '@wo/frontend/wo-file-upload/wo-file-upload.module';
import { WoTextFieldSegModule } from '@wo/frontend/wo-text-field-seg/wo-text-field-seg.module';
import { MultiselectSegModule } from '@wo/frontend/multiselect-seg/multiselect-seg.module';
import { WoTextFieldModule } from '@wo/frontend/wo-text-field/wo-text-field.module';
import { MenuContextualModule } from '@wo/frontend/menu-contextual/menu-contextual.module';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    WoTablaModule,
    PopupGeneralModule,
    SelectPagSegModule,
    AngularDraggableModule,
    WoTextFieldModule,
    WoTextFieldSegModule,
    MultiselectSegModule,
    FechasModule,
    WoFileUploadModule,
    MenuContextualModule
  ],
  declarations: [
    MasOpcionesComponent,
    DocumentoPersonalizadoComponent,
    ElaboradoPorComponent,
    FechasSinRefComponent,
    ListaClasificacionesComponent,
    ListaContactosComponent,
    MasOtrosComponent,
    TerceroExternoComponent,
    TercerosMultiplesComponent
  ],
  exports: [
    MasOpcionesComponent,
    DocumentoPersonalizadoComponent,
    ElaboradoPorComponent,
    FechasSinRefComponent,
    ListaClasificacionesComponent,
    ListaContactosComponent,
    MasOtrosComponent,
    TerceroExternoComponent,
    TercerosMultiplesComponent
  ]
})
export class MasOpcionesModule { }
