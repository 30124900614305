import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { ImprimirDocumentoComponent } from './imprimir-documento.component';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    WoTablaModule,
    PopupGeneralModule,
    SelectPagSegModule,
    AngularDraggableModule
  ],
  declarations: [
    ImprimirDocumentoComponent
  ],
  exports: [
    ImprimirDocumentoComponent
  ]
})
export class ImprimirDocumentoModule { }
