export class TerceroEnvioNominaElectronica {
  public id: number;
  public tipoIdentificacion: string;
  public identificacion: number;
  public primerNombre: string;
  public segundoNombre: string;
  public primerApellido: string;
  public segundoApellido: string;
  public ubicacionCiudad: string;
  public codigo: string;
  public senActivo: boolean;
  public senManejaNomina: string;
  public terceroTipos: string;
  public aplicaICAVentas: boolean;
}
