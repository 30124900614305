import { NgModule } from '@angular/core';
import { SharedModule } from '@wo/frontend/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SelectPagSegModule } from '@wo/frontend/select-pag-seg/select-pag-seg.module';
import { AngularDraggableModule } from 'angular2-draggable';
import { WoTablaModule } from '@wo/frontend/wo-tabla/wo-tabla.module';
import { PopupGeneralModule } from '@wo/frontend/popup-general/popup-general.module';
import { ContabilizacionComponent } from './contabilizacion.component';
import { ContabilizacionBasicaComponent } from './contabilizacion-basica/contabilizacion-basica.component';
import { ContabilizacionAvanzadaComponent } from './contabilizacion-avanzada/contabilizacion-avanzada.component';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    WoTablaModule,
    PopupGeneralModule,
    SelectPagSegModule,
    AngularDraggableModule
  ],
  declarations: [
    ContabilizacionComponent,
    ContabilizacionBasicaComponent,
    ContabilizacionAvanzadaComponent
  ],
  exports: [
    ContabilizacionComponent,
    ContabilizacionBasicaComponent,
    ContabilizacionAvanzadaComponent
  ]
})
export class ContabilizacionModule { }
